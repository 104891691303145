.react-datepicker-popper {
  z-index: 2147483647;
}

.react-datepicker__day--keyboard-selected {
  background: #0ab7d9;
}

.input-date {
  padding: 8px;
}