:root {
  --color-primary: rgb(113, 179, 219);
  --color-primary-dark: #0073AD;
  --color-primary-light: rgba(113, 179, 219, 0.3);

  --color-white: #ffffff;
  --color-black: #000000;
  --color-gray: rgb(97, 96, 97);
  --color-gray-light: rgba(29, 28, 29, 0.05);
  --color-gray-alpha: rgba(0, 0, 0, 0.5);
  --color-gray-alpha90: rgba(0, 0, 0, 0.9);
  --color-primary-alpha: #ca473b41;

  --color-gray-dark: #dfdfdf;
  --color-beige: rgb(250, 246, 242);
  --color-red: #db1010;
  --color-red-dark: #b60c0c;
  --color-red-pink: rgb(230, 18, 93);
  --color-red-pink-dark: rgb(168, 14, 68);
  --color-orange: #de4e2b;
  --color-green: #007a5a;
  --color-green-dark: rgb(1, 78, 58);

  --color-yellow: rgb(250, 187, 58);
  --color-yellow-dark: rgb(180, 135, 44);

  --color-gold: #FFD700;
  --color-silver: #C0C0C0;
  --color-bronze: #8C7853;
}

input,
select {
  outline: 0px;
  border: 0;
  margin: 0;
  font-family: "Poppins", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 15px;
  width: 100%;
  -webkit-appearance: none;
  background-color: transparent;
}

textarea {
  outline: 0px;
  border: 0;
  margin: 0;
  font-family: "Poppins", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 15px;
  width: 100%;
  -webkit-appearance: none;
  background-color: transparent;
  resize: none;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
